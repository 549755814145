import { Box, Button, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import AlertDialog from './dialogs/AlertDialog';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  textAlign: 'center' as 'center',
  justifyContent: 'center',
  padding: '8px',
  height: '100%',
  borderWidth: 1,
  borderRadius: 4,
  borderColor: '#bdbdbd',
  borderStyle: 'solid',
  backgroundColor: 'transparent',
  color: 'rgba(0, 0, 0, 0.87)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const useStyles = makeStyles((theme) => ({
  fileChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& .MuiChip-root': {
      height: 'auto',
      padding: 5,
    },
    '& .MuiChip-label': {
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
    },
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const focusedStyle = {
  borderColor: '#212121',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type DropzoneProps = {
  className?: string;
  files: {
    selectedFiles: File[];
    setSelectedFiles: Function;
  };
  errorMessages?: { [key: string]: string | React.ReactNode };
  maxFiles?: number;
  maxSize?: number;
  excludeTypes?: Array<string>;
  includeTypes?: Array<string>;
  content?: React.ReactNode;
  disableClick?: boolean;
  buttonText?: string;
  maxHeight?: string;
  preview?: string;
  multiple?: boolean;
  resetOnSelect?: boolean;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
};

const StyledDropzone = ({
  className,
  files,
  errorMessages,
  maxSize = 9999999999,
  maxFiles,
  excludeTypes = [],
  includeTypes = [],
  content,
  disableClick = false,
  buttonText,
  maxHeight,
  preview,
  multiple = true,
  resetOnSelect = true,
  imgProps = {},
}: DropzoneProps) => {
  const { t } = useTranslation();
  const { selectedFiles, setSelectedFiles } = files;
  const [error, setError] = useState<string>('');
  const classes = useStyles();
  const selectedFilesSize = resetOnSelect ? 0 : selectedFiles.reduce((prev: any, curr: any) => prev + curr.size, 0);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const droppedSizeSize = acceptedFiles.reduce((totalSize: number, file: File) => totalSize + file.size, 0);
      if (droppedSizeSize + selectedFilesSize > maxSize) {
        setError('size');
        return;
      }
      if (fileRejections.length) {
        const { errors } = fileRejections[0];
        setError(errors[0].code ?? '');
        return;
      }
      // eslint-disable-next-line consistent-return
      return resetOnSelect
        ? setSelectedFiles([...acceptedFiles])
        : setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    },
    [selectedFilesSize, resetOnSelect, maxSize, selectedFiles, setError, setSelectedFiles],
  );

  const fileValidator = (file: any) => {
    const extension = file.name.split('.').pop();
    if (extension === 'exe') {
      return {
        code: 'exe',
        message: `Invalid file type.`,
      };
    }
    if (excludeTypes.includes(file.type) || (includeTypes.length && !includeTypes.includes(file.type))) {
      return {
        code: 'blocked',
        message: `Invalid file type.`,
      };
    }
    if (selectedFiles.map((selected) => selected.name).includes(file.name)) {
      return {
        code: 'duplicate',
        message: `Duplicate file was uploaded.`,
      };
    }
    if (selectedFilesSize + file.size > maxSize) {
      return {
        code: 'size',
        message: `Total file size is larger than the limit of ${maxSize / 1048576}MB.`,
      };
    }
    return null;
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } = useDropzone({
    // accept: { 'image/*': [] },
    onDrop,
    validator: fileValidator,
    multiple: multiple,
    ...(maxFiles && { maxFiles: maxFiles }),
    ...(buttonText && { noClick: true, noKeyboard: true }),
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...selectedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setSelectedFiles(newFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const handleErrorClose = () => {
    setError('');
  };

  return (
    <div className={`container ${className ?? ''}`}>
      {!!error && (
        <AlertDialog
          open={!!error}
          setOpen={(open) => {
            if (!open) setError('');
          }}
          onClose={handleErrorClose}
          title={t('File Upload Error')}
          maxWidth="xs"
          content={
            error === 'duplicate'
              ? errorMessages?.[error] ?? <Trans i18nKey="dropzone:duplicate signature file error" />
              : error === 'size'
              ? errorMessages?.[error] ?? (
                  <Trans i18nKey="dropzone:max file size error" values={{ maxSize: `${maxSize / 1048576}MB` }} />
                )
              : error === 'exe'
              ? errorMessages?.[error] ?? <Trans i18nKey="dropzone:exe file error" />
              : error === 'blocked'
              ? errorMessages?.[error] ?? <Trans i18nKey="dropzone:blocked file error" />
              : errorMessages?.[error] ?? <Trans i18nKey="dropzone:generic file error" />
          }
          action={() => {}}
          buttonsText={[t('Close')]}
          secondaryButton={false}
        />
      )}
      <div {...getRootProps({ style, ...(disableClick && { onClick: (event) => event.stopPropagation() }) })}>
        <input {...getInputProps()} />
        {content}
        {buttonText && (
          <div className="pb-8">
            <Button type="button" onClick={open} variant="contained" color="secondary">
              {buttonText}
            </Button>
          </div>
        )}
        {!!preview ? (
          <div className="flex max-h-full w-full justify-center align-center">
            <img
              alt=""
              src={preview}
              {...imgProps}
              style={{ maxHeight: '100%', objectFit: 'contain', ...imgProps?.style }}
            />
          </div>
        ) : (
          <Box className={classes.fileChips} maxHeight={maxHeight} overflow="auto">
            {selectedFiles.map((file, i) => (
              <Chip key={i} label={file.name} onDelete={removeFile(file)} />
            ))}
          </Box>
        )}
      </div>
    </div>
  );
};

export default StyledDropzone;
