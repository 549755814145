import Layout from './layout';
import { FormWrapper } from '../Form';
import { useContext, useEffect, useRef } from 'react';
import React from 'react'
import { useProcessData } from '../hooks/useProcessData';
import { useAppArgs } from '../hooks/useAppArgs';
import { MessageBoxContext, MessageBoxStateActions, MessageBoxType } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { useAuthApiContext, useAuthContext } from '../context/AuthState/AuthContext';
import { useAppConfig } from '../context/AppState/AppContext';
import { useFormUtils } from '../hooks/useFormUtils';
import { useQuery } from 'react-query';
import { jwtDecode } from 'jwt-decode';
import axios from '../../lib/axios';
import { useSubmitForm } from '../hooks/useSubmitForm';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';

export const Mfp = () => {
  const { isLoading, data } = useProcessData();
  const queryParams = useAppArgs();
  const { getTimeDifference } = useFormUtils();
  const { state: appState, setAppConfig } = useAppConfig();
  const { setToken } = useAuthApiContext();
  const { state: authState } = useAuthContext();
  const { dispatch } = useContext(MessageBoxContext);
  const valid = useRef(validateNodeConfig());
  const submit = useSubmitForm();
  const { dispatch: formDispatch, state: formState } = useFormData();
  const [ inIFrame, setInIframe ] = React.useState<boolean>(false);

  function validateNodeConfig() {
    const { processId, workflowId, nodeID, metadata, returnURL, returnToken } = queryParams;
    return (
      !!processId &&
      !!workflowId &&
      !!nodeID &&
      !!metadata.tenant.region &&
      !!metadata.tenant.slug &&
      !!metadata.userId &&
      !!metadata.token &&
      !!returnURL &&
      !!returnToken
    );
  }

  if (queryParams.debug) {
    console.log('[DEBUG] Node Config:', queryParams);
    console.log('[DEBUG] Process Data:', { isLoading, data });
  }

  const isInIFrame = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const sendAuthRequest = () => {
    if (!window.top) {
      return;
    }
    window.top.postMessage(
      {
        source: 'forms-app-mfp-token',
        payload: { type: 'token-request' },
      },
      '*',
    );
  };

  const listenForMessage = (event: MessageEvent<any>) => {
    if (event.data?.payload?.loadedFromSite) {
        formDispatch({
          type: FormActionType.POPULATE_FORM,
          payload: {...formState, displayAdvancedSubmit: true},
        });
      }
    else if (event?.data?.payload?.tokens) {
      setToken(event.data.payload.tokens?.accessToken);
    }
  };

  useQuery(
    ['tenantData', appState.tenant.id],
    async () => {
      // alert(`https://${appState.tenant.slug}.tenant.${queryParams.fallbackDomain}/api/tenants/${appState.tenant.id}`);
      const response = await axios.get(
        `https://${appState.tenant.region}.web.${queryParams.fallbackDomain}/api/service/tenant`,
        {
          headers: {
            Authorization: 'Bearer ' + authState.token,
          },
        },
      );
      return response.data.timezone;
    },
    {
      enabled: authState.isAuthenticated && !!appState.tenant.region,
      onSuccess: async (timezone) => {
        const approxTimeOffset = await getTimeDifference(timezone, appState.tenant.region);
        setAppConfig({ tenant: { ...appState.tenant, timezone }, timeOffset: approxTimeOffset });
      },
      onError: (err) => {
        const fallback = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('Failed to fetch timezone. Falling back to', fallback);
        setAppConfig({ tenant: { ...appState.tenant, timezone: fallback } });
      },
    },
  );

  useEffect(() => {
    if (!authState.isAuthenticated) {
      const decodedToken = jwtDecode(queryParams.metadata.token);
      if (Date.now() >= (decodedToken.exp ?? 0) * 1000 && process.env.NODE_ENV !== 'development') {
        if (!isInIFrame()) {
          return;
        }
        sendAuthRequest();
      } else {
        setToken(queryParams.metadata.token);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.metadata.token, authState.isAuthenticated]);

  useEffect(() => {
    window.addEventListener('message', listenForMessage);
    return () => {
      window.removeEventListener('message', listenForMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!valid.current) {
      dispatch({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          open: true,
          boxType: MessageBoxType.None,
          title: t('error invalidConfig title'),
          message: t('error invalidConfig body'),
        },
      });
    } else {
      setAppConfig({
        processId: queryParams.processId,
        workflowId: queryParams.workflowId,
        tenant: queryParams.metadata.tenant,
        returnUrl: queryParams.returnURL,
        returnToken: queryParams.returnToken,
        nodeId: queryParams.nodeID,
        debug: queryParams.debug,
      });
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid.current]);

  useEffect(() => {
    setInIframe(isInIFrame());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleSubmitForm = async (): Promise<void> => {
    return submit(data);
  }


  return (
    <Layout
      onSubmit={handleSubmitForm}
      hideHeader={inIFrame}
      inIFrame={inIFrame}
      hideActionBar={formState.displayAdvancedSubmit}
    >
      {!isLoading && !!data.formID && valid.current && <FormWrapper formID={data.formID} />}
    </Layout>
  );
};
