import Layout from './mfp/layout';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAppConfig } from './context/AppState/AppContext';
import { useFormUtils } from './hooks/useFormUtils';
import { trackPromise } from 'react-promise-tracker';
import { AxiosError } from 'axios';
import { MessageBoxContext, MessageBoxStateActions, MessageBoxType } from '@dispatcher-stratus/stratus-react';
import { useTranslation } from 'react-i18next';
import { useAppArgs } from './hooks/useAppArgs';
import { useAuthContext, useAuthApiContext } from './context/AuthState/AuthContext';
import { useHistory, useParams } from 'react-router-dom';
import { useMetadataApi } from './hooks/useMetadataApi';
import { FormDataContext } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { Grid } from '@material-ui/core';
import SaveButton from './embed/SaveButton';
import ResetButton from './embed/ResetButton';
import { useFormApi } from './hooks/useFormApi';
import { useSnackbarApi } from './hooks/useSnackbarApi';
import axios from '../lib/axios';
import { usePersistForm } from './hooks/usePersistForm';

type Props = {
  children: React.ReactNode;
};
export const FormEdit = (props: Props) => {
  const { state: auth } = useAuthContext();
  const { handleAuthSWS } = useAuthApiContext();
  const { state: appState, setAppConfig } = useAppConfig();
  const { state: formState, dispatch: formDispatch } = useContext(FormDataContext);
  const { saveForm } = useFormApi();
  const { fallbackDomain, tenant, fileId, dropId, dropUsername, dropPassword } = useAppArgs();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const { getProcessMetadata, getFileMetadata, getFormMetadata } = useMetadataApi();
  const { massageFormInput } = useFormUtils();
  const { getPersistedForm } = usePersistForm();
  const { showSuccessMessage } = useSnackbarApi();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{
    processId: string;
    formId: string;
    region: string;
    slug: string;
    workflowId: string;
  }>();

  //MARK: QUERIES

  const { data: processMetadata } = useQuery(
    ['processData', params.processId],
    async () => {
      const processData = await trackPromise(getProcessMetadata(params.processId));
      return processData;
    },
    {
      enabled: !!params.processId && !!appState.workflowId && !!auth.isAuthenticated,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['formData', params.formId],
    async () => {
      const formData = await trackPromise(
        getPersistedForm(appState.workflowId, params.processId, params.formId, tenant.region, fallbackDomain, auth.token!, tenant.slug, appState?.tenant?.id),
      );

      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }

      return formData;
    },
    {
      enabled: !!params.formId && !!auth.isAuthenticated && !!processMetadata,
      onSuccess: async (formData) => {
        let defaultValueOverrides = {};
        const frmMetadata = getFormMetadata(processMetadata, params.formId);
        if (frmMetadata) {
          defaultValueOverrides = frmMetadata;
        } else if (fileId) {
          // try look for Form data in the file metadata only for backward compatibility.
          const fileMetadata = await getFileMetadata(fileId);
          defaultValueOverrides = fileMetadata.data.form;
        }

        const massagedFormData = await massageFormInput(
          formData,
          {
            environment: new Map(),
            records: new Map(),
          },
          defaultValueOverrides,
        );

        formDispatch({
          type: FormActionType.POPULATE_FORM,
          payload: massagedFormData,
        });
      },
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['tenantData', appState.tenant.id],
    async () => {
      const response = await axios.get(
        `https://${appState.tenant.slug}.tenant.${fallbackDomain}/api/tenants/${appState.tenant.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        },
      );
      return response.data.details.timezone;
    },
    {
      enabled: auth.isAuthenticated && !!appState.tenant.id && !!appState.tenant.slug,
      onSuccess: (timezone) => {
        setAppConfig({ tenant: { ...appState.tenant, timezone } });
      },
      onError: (err) => {
        const fallback = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log('Failed to fetch timezone. Falling back to', fallback);
        setAppConfig({ tenant: { ...appState.tenant, timezone: fallback } });
      },
    },
  );

  //MARK: EFFECTS
  useEffect(() => {
    if (!auth.isAuthenticated) {
      const success = handleAuthSWS();
      if (!success) {
        history.push(`/unauthrized`);
      }
    }
    setAppConfig({
      formId: params.formId,
      processId: params.processId,
      workflowId: params.workflowId,
      tenant: {
        id: '',
        slug: params.slug,
        region: params.region,
        plan: '',
        name: '',
        timezone: '',
      },
    });
  }, [history, params, auth.isAuthenticated, handleAuthSWS, setAppConfig]);

  async function handleSaveForm() {
    try {
      const drop = {
        dropid: atob(decodeURIComponent(dropId)),
        username: atob(decodeURIComponent(dropUsername)),
        password: atob(decodeURIComponent(dropPassword)),
      };
      console.log(drop);
      await saveForm(formState.formDefinition.fields, drop);
      showSuccessMessage({ text: t('Form Saved.') });
    } catch (err) {
      console.error('failed to save form', err);
      dispatchMessage({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          open: true,
          boxType: MessageBoxType.Ok,
          title: t('error formError'),
          message: `Failed to save form.`,
        },
      });
    }
  }

  return (
    <>
      <Layout hideHeader hideActionBar>
        <Grid container wrap="nowrap" direction="column" spacing={0}>
          <Grid item xs>
            {props.children}
          </Grid>
          {formState.currentPage.number === formState.numPages && (
            <Grid container justifyContent="center" className="my-10">
              <Grid item className="mx-5">
                <ResetButton />
              </Grid>
              <Grid item className="mx-5">
                <SaveButton onClick={handleSaveForm} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Layout>
    </>
  );
};
