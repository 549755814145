import { axiosMockAdapterInstance as mock } from '../lib/axios';
import FreeForm1 from './forms/free-form1';
import FreeForm2 from './forms/free-form2';
import FreeForm3 from './forms/free-form3';
import Form1 from './forms/form1';
import Form2 from './forms/form2';
import Form3 from './forms/form3';

console.log('mocking form...');

mock.onGet('api/forms/1').reply(() => {
  const form = Form1;
  return [200, form];
});

mock.onGet('api/forms/2').reply(() => {
  const form = Form2;
  return [200, form];
});

mock.onGet('api/forms/3').reply(() => {
  const form = Form3;
  return [200, form];
});
mock.onGet('api/forms/4').reply(() => {
  const form = FreeForm1;
  return [200, form];
});
mock.onGet('api/forms/5').reply(() => {
  const form = FreeForm2;
  return [200, form];
});
mock.onGet('api/forms/6').reply(() => {
  const form = FreeForm3;
  return [200, form];
});
