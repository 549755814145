import { axiosMockAdapterInstance as mock } from '../lib/axios';

console.log('mocking session...');

mock
  .onGet(
    `/workflow/node/download?workflowId=testworkflow&nodeId=123&arn=testprocess`,
  )
  .reply(() => {
    console.log(
      'mocking route',
      `/workflow/node/download?workflowId=testworkflow&nodeId=123&arn=testprocess`,
    );
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '1' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });

mock
  .onGet(
    `/workflow/node/download?workflowId=testworkflow2&nodeId=123&arn=testprocess`,
  )
  .reply(() => {
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '2' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });

mock
  .onGet(
    `/workflow/node/download?workflowId=testworkflow3&nodeId=123&arn=testprocess`,
  )
  .reply(() => {
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '3' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });
  mock
  .onGet(
    `/workflow/node/download?workflowId=testworkflow4&nodeId=123&arn=testprocess`,
  )
  .reply(() => {
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '4' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });
  mock
  .onGet(
    `/workflow/node/download?workflowId=testworkflow5&nodeId=123&arn=testprocess`,
  )
  .reply(() => {
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '5' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });

  mock
  .onGet(
    `/workflow/node/download?workflowId=testworkflow6&nodeId=123&arn=testprocess`,
  )
  .reply(() => {
    const metadata = {
      name: 'us-east-1',
      guid: '',
      description: '',
      config: { formId: '6' },
      status: { isValid: true },
      options: { RefNodeId: '68b9c5bace8b46dab8ad2100d24bb8b6' },
    };
    return [200, metadata];
  });

mock
  .onGet('/api/v1/process/testworkflow/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
mock
  .onGet('/api/v1/process/testworkflow2/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
mock
  .onGet('/api/v1/process/testworkflow3/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
  mock
  .onGet('/api/v1/process/testworkflow4/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
  mock
  .onGet('/api/v1/process/testworkflow5/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
  mock
  .onGet('/api/v1/process/testworkflow6/testprocess')
  .reply(() => [200, { data: { files: 'testfile' } }]);
mock
  .onGet('/api/v1/file/testprocess/testfile')
  .reply(() => [200, { data: {}, form: {} }]);
mock.onPut('/api/v1/file/testprocess/testfile').reply(() => [200, {}]);
mock.onPost('/api/send-report').reply(() => [200, {}]);
