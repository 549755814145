import { trackPromise } from "react-promise-tracker";
import { FormField } from "../../interfaces/FormFieldTypes";
import { useAppConfig } from "../context/AppState/AppContext";
import { useAuthContext } from "../context/AuthState/AuthContext";
import { useFormData } from "../context/FormState/FormDataContext";
import { useAppArgs } from "./useAppArgs";
import { useFormApi } from "./useFormApi";
import { useFormUtils } from "./useFormUtils";
import { useMetadataApi } from "./useMetadataApi";
import { useNTFApi } from "./useNTFApi";
import { usePersistForm } from "./usePersistForm";
const Fp = require('lodash/fp');

export const useSubmitForm = () => {
  const queryParams = useAppArgs();
  const { uploadFormToProcessMetadata, uploadFormToFileMetadata } = useMetadataApi();
  const { state: formState } = useFormData();
  const { getDroppoint, getDroppointData } = useNTFApi();
  const { sendReport, uploadFiles, processFileFields } = useFormApi();
  const { compileFormData } = useFormUtils();
  const { state: appState } = useAppConfig();
  const { state: authState } = useAuthContext();
  const { persistFormDefinition } = usePersistForm();
    
  const submit = async (processData?: any) => {
    const droppoint = await getDroppoint(queryParams.processId);
    console.log('uploading file attachments...');
    console.log(formState.formDefinition.fields);
    const {
      fields: updatedFields,
      files,
      droppointCreds,
    }: {
      fields: FormField[];
      files: { fileId: string; file: File; droppointInfo: any }[];
      droppointCreds: any;
    } = await processFileFields(formState.formDefinition.fields, droppoint);
    console.log(updatedFields);
    console.log(files);
    console.log(droppointCreds);
    await uploadFiles(files);
    console.log('compiling form data...');
    const formdata = compileFormData(updatedFields);
    console.log('formdata', formdata);

    let dropFileInfo = [];
    let droppointData: any = {};

    if (!Fp.isEmpty(droppointCreds.dropid)) {
      droppointData = await getDroppointData({
        id: droppointCreds.dropid,
        username: droppointCreds.username,
        password: droppointCreds.password,
      });
      dropFileInfo = droppointData.info.files;
    }

    if (dropFileInfo.length) {
      const fileResponse = await uploadFormToFileMetadata(dropFileInfo[0].file.name, formState.id, formdata);
      console.log('file metadata uploaded', fileResponse);
    } else if (processData && !Fp.isEmpty(processData.fileID)) {
      const fileResponse = await uploadFormToFileMetadata(processData.fileID, formState.id, formdata);
      console.log('file metadata uploaded', fileResponse);
    }

    console.log('uploading metadata...');
    const response = await uploadFormToProcessMetadata(appState.processId, formState.id, formdata, {
      linearform: {
        formId: formState.id,
        formTitle: formState.title,
        formUrl: appState.formUrl,
      },
    });
    console.log('metadata uploaded', response);

    if (!Fp.isEmpty(droppointCreds.dropid)) {
      const dropFiles = droppointData.files;

      const fileUploadBody = {
        files: {
          Normal: dropFiles,
        },
        drop: {
          Normal: {
            id: droppointCreds.dropid,
            username: droppointCreds.username,
            password: droppointCreds.password,
          },
        },
      };

      await sendReport(
        appState.returnUrl,
        appState.returnToken,
        authState.token!,
        !Fp.isEmpty(droppointCreds) ? fileUploadBody : '',
      );
    } else {
      await sendReport(appState.returnUrl, appState.returnToken, authState.token!);
    }

    // persist original form definition.
    if (formState.originalFormDef) {
      const persistFormResponse = await trackPromise(
        persistFormDefinition(appState.workflowId, appState.processId, formState.originalFormDef, queryParams.tenant.region ?? 'us-east-1', queryParams.fallbackDomain),
      ).catch((errPersistForm: any) => {
        console.error('Error persisting form definition:', errPersistForm);
      });
      console.log('persistFormResponse: ', persistFormResponse);
    }

    window.location.href = `https://mfp.${queryParams.mfpDomain}/processing?arn=${appState.processId}`;
  }
  return submit;
}