import { FormField, PageBreakField } from './FormFieldTypes';
export interface FormBaseDataType {
  id: string;
  title: string;
  creator: string;
  advanced: boolean;
  editor: string;
  created: number;
  updated: number;
  formStatus: string;
  formGroup: { id: string };
  valid: boolean;
  height: number;
  width: number;
  formDefinition: {
    config?: {
      customCSS: string;
      gridSize: number;
      styling?: {
        width: {
          num: number;
          unit: string;
        };
        height: {
          num: number;
          unit: string;
        };
      };
      authRequired?: boolean;
      formAuth: string;
      requireRecaptcha: boolean;
    };
    pages?: [
      {
        id: string;
        config: {
          styling?: {
            backgroundColor: string;
            backgroundImage: {
              enabled: boolean;
              url: string | null;
              opacity: number;
              fitType: 'fit' | 'center';
            };
          };
        };
      },
    ];
    targetMfpId: string;
    fields: FormField[];
  };
  currentPage: {
    number: number;
    config: Omit<PageBreakField['config'], 'variable' | 'showHelp' | 'excludeFromMetadata' | 'helpText'>;
  };
  debug: boolean;

  numPages: number;
  errors: Map<string, string>;
  loaded: boolean;
  displayAdvancedSubmit?: boolean
}

export interface FormDataType extends FormBaseDataType {
  originalFormDef: FormBaseDataType | undefined;
}

export const INITIAL_FORM_CONFIG: FormDataType = {
  id: '',
  title: '',
  creator: '',
  editor: '',
  created: 0,
  updated: 0,
  advanced: false,
  width: window.innerWidth,
  height: window.innerHeight,
  formStatus: 'published',
  formGroup: { id: '' },
  valid: false,
  formDefinition: {
    config: {
      customCSS: '',
      gridSize: 1,
      styling: {
        width: {
          num: 100,
          unit: 'px',
        },
        height: {
          num: 100,
          unit: 'px',
        },
      },
      authRequired: true,
      formAuth: 'stratus-user',
      requireRecaptcha: false,
    },
    pages: [
      {
        id: '',
        config: {
          styling: {
            backgroundColor: 'white',
            backgroundImage: {
              enabled: false,
              url: null,
              opacity: 100,
              fitType: 'fit',
            },
          },
        },
      },
    ],
    targetMfpId: '',
    fields: [],
  },
  currentPage: {
    number: 1,
    config: {
      defaultValue: '',
      readonly: false,
      required: false,
      hideBack: false,
      hideNext: false,
      hideHome: false,
      hidePageNumbers: false,
      hideReturn: false,
      hideScan: false,
      title: '',
    },
  },
  numPages: 1,
  errors: new Map<string, string>(),
  loaded: false,
  debug: false,
  originalFormDef: undefined,
};
