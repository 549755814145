import { createTheme } from '@material-ui/core/styles';
import { TreeViewClassKey } from '@material-ui/lab/TreeView';
import { TreeItemClassKey } from '@material-ui/lab/TreeItem';
import * as _styles from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import type {} from '@material-ui/lab/themeAugmentation';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiTreeView: TreeViewClassKey;
    MuiTreeItem: TreeItemClassKey;
  }
}

export const paletteOptions: PaletteOptions = {
  type: 'light',
  primary: {
    main: '#5f3fa5',
    dark: '#422c73',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#a48adb',
    contrastText: '#000',
    dark: '#3d3d3d',
  },
  background: {
    default: '#efefef',
  },
  action: {
    selected: '#a48adb',
    selectedOpacity: 0.5,
  },
};

// A custom theme for this app
const theme = createTheme({
  palette: paletteOptions,
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 4,
      textTransform: 'none', // removes uppercase transformation
      fontWeight: 600,
    },
  },
  MuiAppBar: {
    root: {
      padding: '0px',
    },
    colorPrimary: { backgroundColor: theme.palette.secondary.dark },
    colorDefault: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  MuiTreeItem: {
    root: {
      '&:focus > $content $label': {
        backgroundColor: theme.palette.action.hover,
      },
      '&$selected > $content $label': {
        backgroundColor: _styles.alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
      },
      '&$selected > $content $label:hover, &$selected:focus > $content $label': {
        backgroundColor: _styles.alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: _styles.alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  },
  MuiFab: {
    primary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  MuiInputBase: {
    root: {
      fontSize: 'inherit',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '9px 9px',
    },
    root: {
      '&$focused .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha('#000000', 0.87),
      },
      '& fieldset p': {
        fontSize: '12px !important',
        lineHeight: '1.2em',
        textOverflow: 'unset',
      },
    },
  },
  MuiTextField: {
    root: {
      shrink: {
        border: '1px solid red',
      },
      '& label p': {
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  MuiInputLabel: {
    root: {
      width: '100%',
      paddingRight: '2em',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: '36px',
    },
  },
  MuiCssBaseline: {},
  MuiCollapse: {
    wrapperInner: {
      width: '100%',
    },
  },
  MuiBreadcrumbs: {
    root: { color: 'white !important' },
    li: {
      '& button': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiDialog: {
    paper: {
      minWidth: '300px',
      '& .MuiDialogTitle-root': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        margin: 0,
        paddingLeft: '23px',
        paddingRight: '23px',
        paddingTop: '4px',
        paddingBottom: '4px',
        '& .MuiTypography-root': {
          '& .MuiTypography-body1': {
            fontSize: '16px',
            fontWeight: 700,
          },
        },
      },
      '& .MuiDialogContent-root': {
        padding: '23px',
        paddingBottom: '23px',
        '& .MuiDialogContent-text': {
          margin: '7px 0 7px 0',
        },
        '& .MuiTypography-root': {
          color: 'black',
          fontSize: '14px',
          margin: '0',
        },
      },
      '& .MuiDialogActions-root': {
        paddingTop: '0px',
        paddingRight: '23px',
        paddingBottom: '23px',
      },
    },
  },
  MuiTooltip: {
    tooltipPlacementBottom: {
      marginBottom: '5px',
      marginTop: '5px',
      backgroundColor: '#663399',
      opacity: '0.87 !important',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      color: '#FFFFFF',
    },
  },
  MuiAutocomplete: {
    option: {
      '&[aria-selected=true]': {
        backgroundColor: 'unset',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
  },
  MuiAlert: {
    filledSuccess: {
      backgroundColor: '#2E7D32',
    },
  },
  MuiFormControlLabel: {
    root: {
      '& .MuiTypography-body1': {
        fontFamily: 'unset',
        fontWeight: 'unset',
        fontSize: 'unset',
        width: '100%',
      },
    },
  },
};

export const unsetFontTheme = createTheme({
  palette: paletteOptions,
  typography: {
    body1: {
      fontFamily: 'unset',
      fontWeight: 'unset',
      fontSize: 'unset',
      width: '100%',
    },
  },
});

export default theme;
