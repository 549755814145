import { CssBaseline } from '@material-ui/core';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Landing from './Landing';
import themeMfp from './components/mfp/theme';
import themeStratus from './components/embed/theme';
import {
  MessageBoxContextProvider,
  CustomMessageBox,
  DisableContextAndShortcuts,
} from '@dispatcher-stratus/stratus-react';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Mfp } from './components/mfp';
import { FormDataCollection } from './components/FormDataCollection';
import { FormDataContextProvider } from './components/context/FormState/FormDataContext';
import './__mocks__/form';
import './__mocks__/session';
import Login from './components/Login';
import { AppContextProvider } from './components/context/AppState/AppContext';
import { Form } from './components/Form';
import Success from './components/success/Success';
import Unavailable from './components/unavailable/Unavailable';
import { JobTicketForm } from './components/JobTicketForm';
import { AuthContextProvider } from './components/context/AuthState/AuthContext';
import { AuthState } from './interfaces/AuthState';
import { FormEdit } from './components/EditForm';
import { SnackbarContextProvider, SnackbarState } from './components/context/Snackbar/snackbar-context-provider';
import { AppSnackbar } from './components/Snackbar';
import { FormView } from './components/ViewForm';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });

  const snackbarInitialState: SnackbarState = {
    show: false,
    message: { text: '' },
    messageType: 'success',
  };

  //match user agent against known ChromiumBrowser user agents user on MFP:
  //ChromiumBrowser/1.0
  //ChromiumBrowser/1.1
  //ChromiumBrowser/2.0
  //ChromiumBrowser/2.1
  const isMFP = /(ChromiumBrowser\/[1-2].[0-1])+/.test(navigator.userAgent);

  const authState: AuthState = {
    isAuthenticated: false,
    token: undefined,
    fetched: false,
    region: undefined,
  };

  // authState.isAuthenticated = true;
  // authState.token =
  //   '';
  // authState.fetched = true;
  // authState.region = 'us-east-1';
  const routing = (
    <I18nextProvider i18n={i18n}>
      <AppContextProvider>
        <AuthContextProvider initialState={authState}>
          <MessageBoxContextProvider>
            <SnackbarContextProvider initialState={snackbarInitialState}>
              <Router>
                <Switch>
                  <Route path="/client/mfp">
                    <ThemeProvider theme={themeMfp}>
                      <CssBaseline />
                      <FormDataContextProvider>
                        <Mfp />
                        <CustomMessageBox i18n={i18n}></CustomMessageBox>
                      </FormDataContextProvider>
                    </ThemeProvider>
                  </Route>
                  <Route path="/externalform/:region/:slug/:urlId">
                    <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                      <CssBaseline />
                      <FormDataContextProvider>
                        <FormDataCollection>
                          <Form />
                        </FormDataCollection>
                        <CustomMessageBox i18n={i18n}></CustomMessageBox>
                      </FormDataContextProvider>
                    </ThemeProvider>
                  </Route>
                  <Route path="/internalform/:region/:slug/:urlId">
                    <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                      <CssBaseline />
                      <FormDataContextProvider>
                        <JobTicketForm>
                          <Form />
                        </JobTicketForm>
                        <CustomMessageBox i18n={i18n}></CustomMessageBox>
                      </FormDataContextProvider>
                    </ThemeProvider>
                  </Route>
                  <Route path="/editform/:region/:slug/:workflowId/:processId/:formId">
                    <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                      <CssBaseline />
                      <FormDataContextProvider>
                        <AppSnackbar />
                        <FormEdit>
                          <Form />
                        </FormEdit>
                        <CustomMessageBox i18n={i18n}></CustomMessageBox>
                      </FormDataContextProvider>
                    </ThemeProvider>
                  </Route>
                  <Route path="/view/:region/:slug/:workflowId/:processId/:formId">
                    <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                      <CssBaseline />
                      <FormDataContextProvider>
                        <AppSnackbar />
                        <FormView>
                          <Form viewOnly={true} />
                        </FormView>
                        <CustomMessageBox i18n={i18n}></CustomMessageBox>
                      </FormDataContextProvider>
                    </ThemeProvider>
                  </Route>
                  <Route path="/sso/login">
                    <CssBaseline />
                    <Login />
                  </Route>
                  <Route path="/client">
                    <Landing></Landing>
                  </Route>
                  <Route path="/success">
                    <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                      <CssBaseline />
                      <FormDataContextProvider>
                        <Success />
                      </FormDataContextProvider>
                    </ThemeProvider>
                  </Route>
                  <Route path="/unavailable">
                    <ThemeProvider theme={isMFP ? themeMfp : themeStratus}>
                      <CssBaseline />
                      <Unavailable />
                    </ThemeProvider>
                  </Route>
                  <Redirect from="/" to="/client" />
                </Switch>
              </Router>
            </SnackbarContextProvider>
          </MessageBoxContextProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </I18nextProvider>
  );
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider jss={jss}>
        <DisableContextAndShortcuts />
        <div className="App flex flex-col h-screen v-screen">{routing}</div>
      </StylesProvider>
    </QueryClientProvider>
  );
}
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')!,
});
export default App;
